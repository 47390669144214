import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function NoLogoLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
